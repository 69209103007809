<template>
  <div>
    <CardsOverflow class="mt-1 ">
      <div class="card">
        <div class="card-details">
          <div class="image-container">
            <img src="@/assets/images/wallet.svg" alt="wallet" />
          </div>
          <div>
            <div class="price">{{ formattedBalance(availableBalance) }}</div>
            <div class="text">Ready</div>
          </div>
        </div>

        <div class="flex">
          <div class="expected-bonus ">
            Bonus amount:
            <span class="expected-amount">{{
              formattedBalance(bonusBalance)
            }}</span>
          </div>

          <div class="expected">
            Expected:
            <span class="expected-amount">{{
              formattedBalance(expectedBalance)
            }}</span>
          </div>
        </div>
        <router-link to="/user/dashboard/wallet" class="more-details">
          <img src="@/assets/images/arrow-right-blue.svg" alt="" />
          Go to wallet
        </router-link>
      </div>
      <div class="card">
        <div class="card-details">
          <div class="image-container">
            <img src="@/assets/images/transaction.svg" alt="" />
          </div>
          <div>
            <div class="price">{{ transactionCount }}</div>
            <div class="text">Transactions</div>
          </div>
        </div>
        <router-link to="/user/dashboard/transactions" class="more-details">
          <img src="@/assets/images/arrow-right-blue.svg" alt="" />
          Go to transaction history
        </router-link>
      </div>
      <div class="card">
        <div class="card-details">
          <div class="image-container">
            <img src="@/assets/images/logs.svg" alt="" />
          </div>
          <div>
            <div class="price">{{ toTwoDecimal(readyPoints) }}</div>
            <div class="text">Points</div>
          </div>
        </div>
        <div class="expected">
          Expected point:
          <span class="expected-amount">{{
            toTwoDecimal(expectedPoints)
          }}</span>
        </div>
        <router-link to="/user/dashboard/point-logs" class="more-details">
          <img src="@/assets/images/arrow-right-blue.svg" alt="" />
          View points log
        </router-link>
      </div>
    </CardsOverflow>
  </div>
</template>

<script>
import CardsOverflow from "./Layout/CardsOverflow";

import CF from "../helpers/currencyFormatter";
import userCredentials from "../services/userCredentials";
import realtor from "../services/realtor";

export default {
  name: "Dashboard",
  components: {
    CardsOverflow
  },

  data() {
    return {
      userID: null,
      userToken: null,
      availableBalance: 0,
      expectedBalance: 0,
      bonusBalance: 0,
      readyPoints: null,
      expectedPoints: null,
      transactionCount: null
    };
  },
  computed: {
    showLeaderboard() {
      return this.$store.state.showLeaderboard;
    }
  },
  methods: {
    formattedBalance(amount) {
      return CF.naira.format(amount);
    },
    toTwoDecimal(value) {
      return CF.toTwoDecimal(value);
    },
    getUserFullDetails() {
      userCredentials
        .userData(this.userID)
        .then(data => {
          if (data.success) {
            data.user.token = this.userToken;
            this.$store.dispatch("saveUser", data.user);
          } else {
            this.$toast.error(data.error);
          }
        })
        .catch(() => {
          this.$toast.error("please check your network and refresh the page");
        });
    },
    getDashboardSummary() {
      userCredentials
        .dashboardSummary(this.userID)
        .then(data => {
          if (data.success) {
            this.availableBalance = data.readywallet;
            this.expectedBalance = data.expectedwallet;
            this.transactionCount = data.transactions;
            this.readyPoints = data.readypoint;
            this.expectedPoints = data.expectedpoint;
          } else {
            this.$toast.error(data.error);
          }
        })
        .catch(() => {
          this.$toast.error("please check your network and refresh the page");
        });
    },

    getUserActivationDataAmount() {
      realtor
        .fetchActivationData()
        .then(data => {
          if (data.activateddata) {
            this.bonusBalance =
              data.activateddata.status == 2 ? 0 : data.activateddata.amount;
          } else {
            this.bonusBalance = 0;
          }
        })
        .catch(err => {
          console.log(err);

          this.$toast.error("Please check your network and refresh page");
        });
    }
  },
  created() {
    const user = this.$store.getters.getUser;
    this.userID = user.id;
    this.userToken = user.token;
    this.$root.$refs.user = this;

    // I had to go through all this stress because the data returned when a user logs in
    // is not as complete as when you call the /getuserbyid GET api.

    // for example the returned login object does not have a city object and state object inside
    // of it, passportfile is name instead of url

    // but the object returned when you call getuserbyid has a city object, state object and the
    // passportfile property is a url and not a name.
    if (
      !user.passportfile ||
      user.passportfile.includes("https://") === false
    ) {
      this.getUserFullDetails();
    }
    this.getDashboardSummary();
    this.getUserActivationDataAmount();
  }
};
</script>

<style scoped>
.card {
  background: #ffffff;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  width: 337px;
  height: 162px;
  display: flex;
  flex-direction: column;
}
.card:last-of-type {
  margin-right: 20px;
}
.card-details {
  padding: 45px 53px 0;
  display: flex;
  justify-content: space-between;
  margin-bottom: auto;
}
.card a {
  text-decoration: none;
}

.expected-bonus {
  background-color: rgb(255, 177, 0);
  color: #fff;
  font-size: 12px;
  padding: 6px;
}
.expected {
  background-color: var(--green-color);
  color: #fff;
  width: fit-content;
  margin-left: auto;
  padding: 5px 20px 5px 10px;
  font-size: 12px;
  font-family: "Gotham-Book";
}
.expected .expected-amount {
  font-size: 14px;
  font-weight: 600;
  font-family: "Gotham-Book";
}
.more-details {
  background: var(--primary-color);
  color: #fff;
  height: 30px;
  width: 100%;
  border-radius: 0 0 10px 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.more-details img {
  margin-right: 10px;
}
.card .image-container {
  background: var(--primary-color);
  width: 59px;
  height: 53px;
  border-radius: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.price {
  font-family: "Gotham-Medium";
  text-align: center;
}
.text {
  text-align: center;
  max-width: 100px;
  font-size: 12px;
  font-family: "Gotham-Book";
  margin-top: 5px;
}
@media (max-width: 1199.98px) {
  .card {
    margin-right: 45px;
    width: 300px;
  }
  .card-details {
    padding: 45px 35px 0;
  }
}
@media (max-width: 991.98px) {
  .card {
    margin-right: 30px;
    width: 280px;
  }
  .card-details {
    padding: 45px 25px 0;
  }
}
@media (max-width: 575.98px) {
  .card {
    margin-top: 0;
    width: 337px;
    max-width: 100%;
    height: 162px;
    margin-right: 0;
    margin-bottom: 40px;
  }
  .card:last-of-type {
    margin-right: 0;
  }

  .f {
    border-top-color: red;
  }
}
</style>
